<script>
	import { auth, client } from '../lib/data.js';
    $client.authStore.clear();
    window.location = '/login';
</script>

<div class="flex flex-col items-center justify-center w-full h-full">
    <h1 class="text-6xl font-bold text-center">Logging you out.<br>Goodbye!</h1>
</div>

<style>
    
</style>