<script>
    export let currentRoute;
	import { auth, client, imageUpload } from '../lib/data.js';
    import { navigateTo } from 'svelte-router-spa';
    import Select from 'svelte-select';
    import { onMount } from 'svelte';
    import Switch from '../lib/Switch.svelte';
    import Toast from '../lib/toasts';
    import AddMediaBtn from '../lib/AddMediaBtn.svelte';

    import { customAlphabet } from 'nanoid';
    const nanoid = customAlphabet('6789BCDFGHJKLMNPQRTWbcdfghjkmnpqrtwz', 9);

    let product = {}, tags = [], page = 'details', ready = false;
    async function load() {
        ready = false;
        product = await $client.records.getOne('products', currentRoute.namedParams.id, {
            expand: 'tags'
        });
        product.description = product?.description.replaceAll('/BREAK/', `\n`);
        product.tags = product['@expand'].tags;
        if (product.dimensions.length != 3) product.dimensions = [...product.dimensions, ...Array(3 - product.dimensions.length)]
        tags = await $client.records.getFullList('tags');
        ready = true;
    };

    async function save(close = true) {
        let productData = product;
        productData.tags = (product.tags ?? []).map(a => a.id);
        productData.description = productData.description.replaceAll('\n', '/BREAK/');
        await $client.records.update('products', product.id, productData);
        Toast.success('Saved');
        if (close == true) {
            navigateTo('/products');
        } else {
            load();
        };
    };

    async function del() {
        await $client.records.delete('products', product.id);
        Toast.danger('Deleted');
        navigateTo('/products');
    };

    onMount(async () => {
        await load();
    });
</script>

{#if ready}
<!-- <div class="w-full h-screen max-h-screen overflow-hidden"> -->
    <div class="flex flex-row items-center justify-between w-full p-5 bg-white rounded-lg">
        <div class="flex flex-col items-start justify-center flex-1">
            <button class="hover:text-gray-500 w-full text-left text-gray-700" on:click={() => navigateTo('/products')}>← Back to Products</button>
            <h1 class="w-full text-2xl font-bold text-left">{product.name}</h1>
        </div>

        <div class="rounded-xl flex flex-row overflow-hidden">
            <!-- <button class="bg-brand hover:bg-pink-600 flex flex-row items-center justify-start w-full p-3 text-lg text-white transition-colors duration-500 ease-in-out" on:click={() => save()}>
                <img src="/assets/img/save.svg" class="w-7 aspect-square invert" alt="Save Icon" />
            </button> -->
            <button class="bg-brand hover:bg-pink-600 flex flex-row items-center justify-start w-full p-3 text-lg text-white transition-colors duration-500 ease-in-out" on:click={() => save(false)}>
                <img src="/assets/img/save.svg" class="w-7 aspect-square invert" alt="Save Icon" />
            </button>
            <button class="hover:bg-red-600 flex flex-row items-center justify-start w-full p-3 text-lg text-white transition-colors duration-500 ease-in-out bg-red-500" on:click={() => { if (confirm('Are you sure you want to delete this?') == true) del(); }}>
                <img src="/assets/img/trash.svg" class="w-7 aspect-square invert" alt="Delete Icon" />
            </button>
        </div>
    </div>
    <div class="flex flex-row items-center justify-center w-full h-full max-h-full mt-5 overflow-hidden">
        <div class="flex flex-col items-center justify-start flex-1 h-full max-h-full mr-5 overflow-hidden">
            <div class="flex flex-row items-center justify-between w-full gap-3 mb-5 overflow-hidden rounded-lg">
                <button on:click={() => page = 'details'} class="flex-1 p-3 {page == 'details' ? 'bg-pink-100' : 'bg-white'} text-center rounded-lg">Product Details</button>
                {#if product.type == 'complex'}
                <button on:click={() => page = 'attributes'} class="flex-1 p-3 {page == 'attributes' ? 'bg-pink-100' : 'bg-white'} text-center rounded-lg">Attributes</button>
                <button on:click={() => page = 'variants'} class="flex-1 p-3 {page == 'variants' ? 'bg-pink-100' : 'bg-white'} text-center rounded-lg">Variants</button>
                {/if}
            </div>

            <div class="{page == 'details' ? '' : 'hidden'} flex-1 w-full max-h-full p-5 overflow-y-auto bg-white rounded-lg">
                <h2 class="mb-2 text-xl font-semibold">Product Details</h2>
                <p>Name</p>
                <input bind:value={product.name}>
                
                <p>Tags</p>
                <Select placeholder="Accessories, Gifts" items={tags} bind:value={product.tags} isMulti="true" labelIdentifier="name" optionIdentifier="id" containerStyles="border-radius: 0.75rem; background-color: rgb(226, 232, 240); border: none; width: 100%; margin-bottom: 0.75rem; cursor: pointer;" inputStyles="border-radius: 0.75rem; width: 100%;" />
        
                <p>Description</p>
                <textarea class="h-64 resize-none" bind:value={product.description} />
            </div>
            
            {#if product.type == 'complex'}
            <div class="{page == 'attributes' ? '' : 'hidden'} flex-1 w-full max-h-full p-5 overflow-y-auto bg-white rounded-lg">
                <h2 class="mb-2 text-xl font-semibold">Attributes</h2>
                <button class="hover:bg-green-500 flex flex-row items-center justify-start p-2 mb-2 mr-5 text-white transition-colors duration-500 ease-in-out bg-green-600 rounded-lg" on:click={() => product.attributes = [{ id: nanoid(), label: '', type: '', variated: true }, ...product.attributes]}>
                    <img src="/assets/img/plus.svg" class="aspect-square invert w-6 mr-2" alt="Plus Icon" />
                    <h3 class="pr-2">Add Attribute</h3>
                </button>

                {#each product.attributes ?? [] as attr, i (attr.id)}
                    <div id={attr.id} class="mb-7 border-l-brand w-full pt-3 pb-2 pl-5 border-l-4">
                        <div class="flex flex-row items-center justify-start w-full gap-5">
                            <p>Name</p>
                            <p>Type</p>
                        </div>
                        <div class="flex flex-row items-center justify-start w-full gap-5">
                            <input bind:value={attr.label}>
                            <select bind:value={attr.type}>
                                <option value="text">Text</option>
                                <!-- <option value="number">Number</option> -->
                                <option value="select">Multi Select</option>
                                <option value="boolean">Boolean (Yes / No)</option>
                            </select>
                        </div>
                        {#if attr.type == 'select'}
                        <p>Multi Select Options <span class="ml-2 text-sm text-gray-700">(Comma Separated)</span></p>
                        <input value={(attr.options ?? []).join(', ')} on:change={e => attr.options = e.target.value.replaceAll(', ', ',').split(',')}>
                        {/if}
                        
                        <p>When placing a custom order, this attribute is:</p>
                        <div class="flex flex-row items-center justify-end w-full gap-5">
                            <div class="flex flex-row items-center justify-start flex-1 select-none">
                                <span class="w-fit pb-1 my-4 mr-3 text-lg text-right">Customer Controlled</span>
                                <Switch bind:value={attr.variated} bool={false} />
                                <span class="flex-1 pb-1 my-4 ml-3 text-lg text-left">Shop Controlled</span>
                            </div>

                            <button class="hover:bg-red-500 flex flex-row items-center justify-start p-2 text-white transition-colors duration-500 ease-in-out bg-red-600 rounded-lg" on:click={() => { if (confirm('Are you sure you want to delete this?') == true) { product.attributes.splice(i, 1); product.attributes = product.attributes; } }}>
                                <img src="/assets/img/trash.svg" class="aspect-square invert w-6 mr-2" alt="Plus Icon" />
                                <h3 class="pr-2">Delete Attribute</h3>
                            </button>
                        </div>
                    </div>
                {/each}
            </div>
    
            <div class="{page == 'variants' ? '' : 'hidden'} flex-1 w-full max-h-full p-5 overflow-y-auto bg-white rounded-lg">
                <div class="mb-7 grid w-full grid-cols-3 grid-rows-2 select-none">
                    <h2 class="text-xl font-semibold {product.allowCustom == true ? 'col-span-2' : 'col-span-3'}">Variants</h2>

                    {#if product.allowCustom == true}
                    <div class="flex-1 row-span-2">
                        <p>Custom Order Price ($)</p>
                        <input bind:value={product.price} type="number">
                    </div>
                    {/if}

                    <div class="flex flex-row justify-start items-center w-full {product.allowCustom == true ? 'col-span-2' : 'col-span-3'}">
                        <button class="hover:bg-green-500 flex flex-row items-center justify-start p-2 mr-5 text-white transition-colors duration-500 ease-in-out bg-green-600 rounded-lg" on:click={() => product.variations = [{
                            id: nanoid(),
                            details: product.attributes.filter(a => a.variated == true).map(b => {
                                return { ...b, value: ''}
                            }),
                            image: 'https://via.placeholder.com/150.png',
                            label: 'Untitled Variant',
                            price: 0,
                            stocked: false
                        }, ...product.variations]}>
                            <img src="/assets/img/plus.svg" class="aspect-square invert w-6 mr-2" alt="Plus Icon" />
                            <h3 class="pr-2">Create Variant</h3>
                        </button>

                        <div class="flex flex-row items-center justify-center flex-1 h-10">
                            <Switch bind:value={product.allowCustom} />
                            <span class="flex-1 ml-3 text-lg text-left">Allow Custom Orders</span>
                        </div>
                    </div>
                </div>

                <!-- <div class="flex flex-col items-center justify-start w-full gap-2 mb-3 overflow-hidden"> -->
                    {#each product.variations ?? [] as variant, i (variant.id)}
                        <div id={variant.id} class="mb-7 border-l-brand w-full pt-3 pb-2 pl-5 border-l-4">
                            <div class="flex flex-row items-center justify-start w-full gap-5">
                                <div class="flex-1">
                                    <p>Name</p>
                                    <input bind:value={variant.label}>
                                </div>

                                <AddMediaBtn on:select={(e) => variant.image = e.detail.file} fullw={false} preview={true} value={variant.image} />
                                <!-- <label for="{variant.id}imageupload" class="rounded-xl bg-slate-200 placeholder:text-gray-600 group flex flex-row items-center justify-start flex-1 p-3 pb-2 mb-3 overflow-hidden transition-shadow duration-200 ease-in-out cursor-pointer select-none">
                                    <img src={variant.image} alt="Image Preview" class="aspect-square object-cover h-10 mr-3 -mt-1">
                                    <p class="group-hover:text-gray-800"><b>Upload New Image</b><br>Accepts any image file</p>
                                    <input id="{variant.id}imageupload" class="hidden" type="file" accept="image/*" on:change={async e => variant.image = await imageUpload(e)}>
                                </label> -->
                            </div>
        
                            <div class="gap-x-5 gap-y-3 grid w-full grid-cols-3">
                                {#each (variant.details ?? []) as detail}
                                <div class="w-full">
                                    <p>{detail.label}</p>
                                    {#if detail.type == 'select'}
                                    <select bind:value={detail.value}>
                                        {#each detail.options as opt}
                                            <option value={opt}>{opt}</option>
                                        {/each}
                                    </select>
                                    {:else}
                                    <input bind:value={detail.value}>
                                    {/if}
                                </div>
                                {/each}
                            </div>
                            
                            <div class="flex flex-row items-center justify-start w-full gap-5">
                                <div class="flex-1">
                                    <p>Price ($)</p>
                                    <input bind:value={variant.price} type="number">
                                </div>

                                <div class="flex flex-row items-center justify-start flex-1 select-none">
                                    <Switch bind:value={variant.stocked} />
                                    <span class="flex-1 pb-1 my-4 ml-3 text-lg text-left">Stocked</span>
                                </div>

                                <button class="hover:bg-red-500 flex flex-row items-center justify-start p-2 text-white transition-colors duration-500 ease-in-out bg-red-600 rounded-lg" on:click={() => { if (confirm('Are you sure you want to delete this?') == true) { product.variations.splice(i, 1); product.variations = product.variations; } }}>
                                    <img src="/assets/img/trash.svg" class="aspect-square invert w-6 mr-2" alt="Plus Icon" />
                                    <h3 class="pr-2">Delete Variant</h3>
                                </button>
                            </div>
                        </div>
                    {/each}
                <!-- </div> -->
            </div>
            {/if}
        </div>

        <div class="w-1/3 h-full max-h-full p-5 overflow-y-auto bg-white rounded-lg">        
            <div class="flex flex-row items-center justify-start w-full select-none">
                <Switch bind:value={product.enabled} />
                <span class="flex-1 pb-1 my-4 ml-3 text-lg text-left">Visible</span>
            </div>
        
            <div class="flex flex-row items-center justify-start w-full select-none">
                <Switch bind:value={product.stocked} />
                <span class="flex-1 pb-1 my-4 ml-3 text-lg text-left">In Stock</span>
            </div>

            <div class="flex flex-row items-center justify-start w-full select-none">
                <span class="flex-1 pb-1 my-4 mr-3 text-lg text-right">Simple</span>
                <Switch value={product.type == 'simple' ? false : true} bool={false} on:change={e => product.type = (e.detail == false ? 'simple' : 'complex')} />
                <span class="flex-1 pb-1 my-4 ml-3 text-lg text-left">Complex</span>
            </div>
            
            {#if product.type == 'simple'}
                <p>Price ($)</p>
                <input bind:value={product.price} type="number">

                <p>Stock Level</p>
                <input bind:value={product.stock} type="number">
            {/if}
        
            <!-- <p>Dimensions (cm)</p>
            <div class="flex flex-row items-center justify-center w-full">
                <input bind:value={product.dimensions[0]} placeholder="Length">
                <input bind:value={product.dimensions[1]} placeholder="Width" class="ml-2">
                <input bind:value={product.dimensions[2]} placeholder="Height" class="ml-2">
            </div> -->

            <p>Main Image</p>
            <AddMediaBtn on:select={(e) => product.mainimage = e.detail.file} />
            <!-- <label for="mainimageupload" class="rounded-xl bg-slate-200 placeholder:text-gray-600 group flex flex-row items-center justify-start w-full p-3 pb-2 mb-3 overflow-hidden transition-shadow duration-200 ease-in-out cursor-pointer select-none">
                <img src="/assets/img/image.svg" alt="Image Icon" class="aspect-square object-cover h-10 mr-3 -mt-1">
                <p class="group-hover:text-gray-800"><b>Upload New Image</b><br>Accepts any image file</p>
                <input id="mainimageupload" class="hidden" type="file" accept="image/*" on:change={async e => product.mainimage = await imageUpload(e)}>
            </label> -->
            <div class="rounded-xl relative w-full mb-5 overflow-hidden">
                <img src={product.mainimage} alt="Logo" class="w-full overflow-hidden">
                <img src="/assets/img/close-circle.svg" class="aspect-square shrink-0 absolute top-0 right-0 self-start w-8 p-1 m-2 bg-white rounded-full cursor-pointer select-none" alt="Close Button" on:click={() => product.mainimage = 'https://via.placeholder.com/150.png'}>
            </div>

            <p>Additional Images</p>
            <AddMediaBtn on:select={(e) => product.images = [...product.images, e.detail.file]} />
            <!-- <label for="extraimageupload" class="rounded-xl bg-slate-200 placeholder:text-gray-600 group flex flex-row items-center justify-start w-full p-3 pb-2 mb-3 overflow-hidden transition-shadow duration-200 ease-in-out cursor-pointer select-none">
                <img src="/assets/img/image.svg" alt="Image Icon" class="aspect-square object-cover h-10 mr-3 -mt-1">
                <p class="group-hover:text-gray-800"><b>Upload New Image</b><br>Accepts any image file</p>
                <input id="extraimageupload" class="hidden" type="file" accept="image/*" on:change={async e => product.images = [...product.images, await imageUpload(e)]}>
            </label> -->
            {#each product.images as image, i}
                <div class="relative w-full overflow-hidden rounded-xl {i != 0 ? 'mt-3' : ''}">
                    <img src={image} alt="Logo" class="w-full overflow-hidden">
                    <img src="/assets/img/close-circle.svg" class="aspect-square shrink-0 absolute top-0 right-0 self-start w-8 p-1 m-2 bg-white rounded-full cursor-pointer select-none" alt="Close Button" on:click={() => { product.images.splice(i, 1); product.images = product.images; }}>
                </div>
            {/each}

            <!-- {#if variantEditing?.label}
                <hr class="my-5 border-t-2">

                <h1 class="w-full mb-1 text-2xl font-bold text-left">Editing: {variantEditing.label}</h1>

                <p>Label</p>
                <input bind:value={variantEditing.label}>

                <p>Image</p>
                <label for="variantimageupload" class="rounded-xl bg-slate-200 placeholder:text-gray-600 group flex flex-row items-center justify-start w-full p-3 mb-3 overflow-hidden transition-shadow duration-200 ease-in-out cursor-pointer select-none">
                    <img src={variantEditing.image} alt="Logo" class="aspect-square object-cover h-12 mr-3">
                    <p class="group-hover:text-gray-800"><b>Upload New Image</b><br>Accepts any image file</p>
                    <input id="variantimageupload" class="hidden" type="file" accept="image/*" on:change={async e => variantEditing.image = await imageUpload(e)}>
                </label>

                <p>Price ($)</p>
                <input bind:value={variantEditing.price} type="number">

                {#each product.attributes as attr}
                    <p>{attr.label}</p>
                    <input value={variantEditing.details.find(a => a.label == attr.label).value ?? ''} on:change={(e) => {
                        if (variantEditing.details.find(a => a.label == attr.label)) {
                            variantEditing.details.find(a => a.label == attr.label).value = e.target.value;
                        } else {
                            variantEditing.details = [...variantEditing.details, { label: attr.label, value: e.target.value }]
                        };
                    }}>
                {/each}

                <button class="bg-brand hover:bg-pink-600 flex flex-row items-center justify-start w-full p-3 mb-2 text-lg text-white transition-colors duration-500 ease-in-out rounded-lg" on:click={() => { product.variations[product.variations.findIndex(a => a.id == variantEditing.id)] = variantEditing; variantEditing = {}; }}>
                    <img src="/assets/img/save.svg" class="w-7 aspect-square invert mr-3" alt="Save Icon" />
                    <h3>Save Variant</h3>
                </button>
            {/if} -->
        </div>
    </div>
<!-- </div> -->
{/if}

<style>
    p {
        @apply w-full text-left mb-1;
    }
    input, textarea, select {
        @apply p-3 rounded-xl overflow-hidden bg-slate-200 transition-shadow duration-200 ease-in-out w-full mb-3 placeholder:text-gray-600;
    }
    input:focus, textarea:focus, select:focus, input:active, textarea:active, select:active {
        @apply ring-2 ring-pink-300;
    }
</style>