<script>
	import { auth, client } from '../lib/data.js';
    import { navigateTo } from 'svelte-router-spa';
    import { onMount } from 'svelte';
    import Toast from '../lib/toasts';

    let tags = [], etag = {};
    onMount(async () => {
        getTags();
    });

    $client.realtime.subscribe('tags', () => getTags());

    async function getTags() {
        tags = await $client.records.getFullList('tags', null);
    };

    async function newTag() {
        let data = {
            'name': 'Untitled Tag',
            'color': '#F121A6',
            'featured': false
        };
        etag = await $client.records.create('tags', data);
    };

    async function saveTag() {
        await $client.records.update('tags', etag.id, etag);
        etag = {};
        Toast.success('Saved');
        getTags();
    };

    async function deleteTag() {
        await $client.records.delete('tags', etag.id);
        etag = {};
        Toast.danger('Deleted');
        getTags();
    };
</script>

{#if tags.length > 0}
<div class="flex flex-row items-center justify-between w-full h-screen overflow-hidden">
    <div class="flex flex-col items-center justify-start flex-1 h-full max-h-full p-5 overflow-y-auto bg-white rounded-lg">
        <div class="flex flex-row items-center justify-between w-full mb-3">
            <h1 class="text-2xl font-bold text-left">Tags ({tags.length})</h1>
            <button class="hover:bg-green-500 flex flex-row items-center justify-start p-3 text-lg text-white transition-colors duration-500 ease-in-out bg-green-600 rounded-lg" on:click={() => newTag()}>
                <img src="/assets/img/plus.svg" class="w-7 aspect-square invert mr-3" alt="Plus Icon" />
                <h3 class="pr-2">Create Tag</h3>
            </button>
        </div>

        {#each tags as tag}
        <span class="w-full px-1 h-[1px] my-2 bg-gray-300"></span>

        <div class="group hover:bg-gray-200 {etag.id == tag.id ? 'bg-gray-200' : ''} flex flex-row items-center justify-start w-full p-3 rounded-lg cursor-pointer" on:click={() => etag = JSON.parse(JSON.stringify(tag))}>
            <span class="p-3 mr-5 bg-transparent border-[7px] rounded-full" style="border-color: {tag.color};"></span>
            <div class="flex flex-col items-start justify-center flex-1 h-full">
                <h1 class="w-full text-xl font-bold text-left">{tag.name} <span class="ml-3 text-base text-gray-600">{tag.id}</span></h1>
                {#if tag.featured}<h3 class="text-lg font-semibold {tag.featured ? 'text-green-600' : 'text-red-600'}">Featured</h3>{/if}
            </div>
            <img src="/assets/img/arrow.svg" class="aspect-square h-12 rotate-90" alt="Arrow">
        </div>
        {/each}
    </div>
    <div class="sticky top-0 flex flex-col items-center justify-start {etag?.id ? 'w-1/3 px-5 ml-5' : 'w-0'} transition-all duration-500 ease-in-out h-full py-5 bg-white max-h-full overflow-y-auto rounded-lg">
        {#if etag?.id}
        <h1 class="w-full mb-5 text-2xl font-bold text-left cursor-pointer" on:click={() => etag = {}}>Editing: {etag.name}</h1>

        <p>Name</p>
        <input bind:value={etag.name}>
        <p>Colour</p>
        <input bind:value={etag.color} type="color" class="h-16">
        <div class="flex flex-row items-center justify-center w-full mb-2 select-none">
            <label for="enabled" class="px-4 py-2 rounded-lg mr-2 font-bold cursor-pointer text-white aspect-square flex justify-center items-center {etag.featured == true ? 'bg-green-500' : 'bg-red-400'}">{etag.featured == true ? '✓' : '✕'}</label>
            <input type="checkbox" id="enabled" class="hidden" bind:checked={etag.featured}>
            <label for="enabled" class="flex-1 my-4 text-lg cursor-pointer">Featured</label>
        </div>
        <button class="bg-brand hover:bg-pink-600 flex flex-row items-center justify-start w-full p-3 text-lg text-white transition-colors duration-500 ease-in-out rounded-lg" on:click={() => saveTag()}>
            <img src="/assets/img/save.svg" class="w-7 aspect-square invert mr-3" alt="Save Icon" />
            <h3>Save Tag</h3>
        </button>
        <button class="hover:bg-red-600 flex flex-row items-center justify-start w-full p-3 mb-3 text-lg text-white transition-colors duration-500 ease-in-out bg-red-500 rounded-lg" on:click={() => { if (confirm('Are you sure you want to delete this?') == true) deleteTag(); }}>
            <img src="/assets/img/trash.svg" class="w-7 aspect-square invert mr-3" alt="Delete Icon" />
            <h3>Delete Tag</h3>
        </button>
        {/if}
    </div>
</div>
{/if}

<style>
    p {
        @apply w-full text-left mb-1;
    }
    input, textarea {
        @apply p-3 rounded-xl overflow-hidden bg-slate-200 transition-shadow duration-200 ease-in-out w-full mb-3 placeholder:text-gray-600;
    }
    input:focus, textarea:focus, input:active, textarea:active {
        @apply ring-2 ring-pink-300;
    }
</style>