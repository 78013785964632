<script>
    export let value = false, bool = true;
    
    import { createEventDispatcher } from 'svelte';
    const dispatch = createEventDispatcher();

    function update() {
        dispatch('change', value);
    }
</script>

<label class="switch">
    <input type="checkbox" bind:checked={value} on:change={update}>
    <span class="slider {bool == true ? 'bool' : 'nobool'}"></span>
</label>

<span class="slider bool nobool" style="display: none;"></span>

<style>
    .switch {
        position: relative;
        display: inline-block;
        width: 3.3825rem;
        height: 1.65rem;
        flex-shrink: 0;
    }

    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 9999px;
    }

    .slider.nobool {
        @apply bg-blue-500;
    }

    .slider.bool {
        background-color: #ccc;
    }

    input:checked + .slider.bool {
        @apply bg-blue-500;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 1rem;
        width: 1rem;
        left: 0.33rem;
        bottom: 0.33rem;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 50%;
    }

    input:checked + .slider:before {
        transform: translateX(1.7325rem);
    }
</style>