<script>
	import { auth, client, imageUpload } from '../lib/data.js';
    import { onMount } from 'svelte';
    import Toast from '../lib/toasts';

    export let selected = {}, role = 'gallery';
    let media = [];
    onMount(async () => {
        media = await $client.records.getFullList('media', 400);
    });

    async function getMeta(url) {
        return new Promise((resolve, reject) => {
            let img = new Image();
            img.onload = () => resolve(img);
            img.onerror = () => reject();
            img.src = url;
        });
    };

    $client.realtime.subscribe('media', async () => {
        media = await $client.records.getFullList('media', 400);
    });

    async function save() {
        await $client.records.update('media', selected.id, selected);
        Toast.success('Saved');
        media = await $client.records.getFullList('media', 400);
    };

    async function del() {
        await $client.records.delete('media', selected.id);
        Toast.danger('Deleted');
        selected = {};
        media = await $client.records.getFullList('media', 400);
    };

    import { createEventDispatcher } from 'svelte';
    const dispatch = createEventDispatcher();
</script>

<div class="flex flex-col items-start justify-start w-full h-full max-h-full p-5 bg-white rounded-lg">
    <div class="flex flex-row items-center justify-between w-full">
        <h1 class="flex-1 text-2xl font-bold text-left">Media Gallery</h1>
        {#if role == 'picker'}
        <img src="/assets/img/close-circle.svg" class="aspect-square shrink-0 w-12 rounded-full cursor-pointer select-none" alt="Close Button" on:click={() => dispatch('close')}>
        {/if}
    </div>
    <div class="flex flex-row items-center justify-between w-full h-full max-h-full pt-5 overflow-y-hidden">
        <!-- <div class="grid flex-1 h-full max-h-full grid-cols-5 gap-5 pr-5 overflow-y-auto"> -->
        <div class="flex flex-row flex-wrap flex-1 h-full max-h-full gap-5 overflow-y-auto">
            {#each media.sort((a, b) => a.created < b.created ? 1 : -1) as item}
                <div class="aspect-square drop-shadow-lg group basis-1/6 flex-1 overflow-hidden bg-white rounded-lg cursor-pointer" on:click={() => selected = item}>
                    <img src={item.file} class="aspect-square object-cover w-full h-full rounded-lg shadow-md" alt={item.title}>
                    <p class="bg-slate-600 font-code group-hover:-translate-y-14 translate-y-14 h-14 bg-opacity-80 flex flex-col items-start justify-center w-full p-2 text-sm text-left text-white break-all transition-transform duration-300 ease-in-out transform">{item.title}</p>
                </div>
            {/each}
        </div>
        <div class="max-w-1/4 flex flex-col items-center justify-start w-1/4 h-full pl-5">
            {#if selected?.file}
            <img src={selected.file} class="drop-shadow-md w-full bg-white rounded-lg" alt={selected.title}>
            <input class="w-full mt-5 text-xl font-bold text-left" bind:value={selected.title} on:change={() => save()} />

            {#await getMeta(selected.file) then imgmeta}
                <p class="w-full mb-3 text-lg text-left">Size: {imgmeta.width} x {imgmeta.height}</p>
            {/await}

            <!-- <button class="bg-brand hover:bg-pink-600 flex flex-row items-center justify-start w-full p-3 mb-3 text-lg text-white transition-colors duration-500 ease-in-out rounded-lg" on:click={() => save()}>
                <img src="/assets/img/save.svg" class="w-7 aspect-square invert mr-3" alt="Save Icon" />
                <h3>Save Image</h3>
            </button> -->

            <button class="hover:bg-red-600 flex flex-row items-center justify-start w-full p-3 mb-3 text-lg text-white transition-colors duration-500 ease-in-out bg-red-500 rounded-lg" on:click={() => { if (confirm('Are you sure you want to delete this?') == true) del(); }}>
                <img src="/assets/img/trash.svg" class="w-7 aspect-square invert mr-3" alt="Delete Icon" />
                <h3>Delete Image</h3>
            </button>
            {/if}
        </div>
    </div>
    <div class="flex flex-row items-center justify-between w-full">
        <!-- <label for="imageupload" class="rounded-xl bg-slate-200 placeholder:text-gray-600 group flex flex-row items-center justify-start flex-1 p-3 pb-2 mt-3 overflow-hidden transition-shadow duration-200 ease-in-out cursor-pointer select-none">
            <img src="/assets/img/image.svg" alt="Image Icon" class="aspect-square object-cover h-10 mr-3 -mt-1">
            <p class="group-hover:text-gray-800 mb-1"><b>Upload New Image(s)</b><br>Accepts any image file</p>
            <input id="imageupload" class="hidden" type="file" accept="image/*" multiple="true" on:change={async e => {
                let files = await imageUpload(e);
                for (const file of files) {
                    $client.records.create('media', file)
                };
            }}>
        </label> -->
        <a class="rounded-xl bg-slate-200 placeholder:text-gray-600 group flex flex-row items-center justify-start flex-1 p-3 pb-2 mt-3 overflow-hidden transition-shadow duration-200 ease-in-out cursor-pointer select-none" target="_blank" href="https://trs-imageupload.pages.dev/">
            <img src="/assets/img/image.svg" alt="Image Icon" class="aspect-square object-cover h-10 mr-3 -mt-1">
            <p class="group-hover:text-gray-800 mb-1"><b>Upload New Image(s)</b><br>Accepts any image file</p>
        </a>
        {#if role == 'picker' && selected.file}
            <button class="rounded-xl self-end w-1/3 p-2 px-10 ml-5 text-lg font-bold text-white bg-blue-500" on:click={() => dispatch('select', selected)}>Select<br>"{selected.title}"</button>
        {/if}
    </div>
</div>

<style>
    input, textarea, select {
        @apply p-3 rounded-xl overflow-hidden bg-slate-200 transition-shadow duration-200 ease-in-out w-full mb-3 placeholder:text-gray-600;
    }
    input:focus, textarea:focus, select:focus, input:active, textarea:active, select:active {
        @apply ring-2 ring-pink-300;
    }
</style>
