<svelte:head>
    <title>Admin | The Rosy Shell</title>
</svelte:head>

<script>
    import PocketBase from 'pocketbase';

    import { Router } from 'svelte-router-spa'
    import { routes } from './routes';
    import { onMount } from 'svelte';
    
    import Sidebar from './lib/Sidebar.svelte';
	import { auth, client } from './lib/data.js';

    import { SvelteToast } from '@zerodevx/svelte-toast';
    import Toast from './lib/Toast.svelte';
    
    $client = new PocketBase('https://db.therosyshell.co.nz');
    // $client.autoCancellation(false);
    $client.beforeSend = (url, reqConfig) => {
        delete reqConfig.signal;
        return reqConfig;
    };

    onMount(async () => {
        if ($auth?.token) {
            let user = await fetch('https://db.therosyshell.co.nz/api/admins/refresh', {
                'method': 'POST',
                'headers': {
                    'Authorization': 'Admin ' + $auth.token
                }
            }).then(res => { return res.json() });

            $auth = user.user;
            $auth.token = user.token;
        };
    });
</script>

<div class="font-clean bg-slate-100 flex flex-row items-center justify-center w-screen h-screen max-h-screen">
    <Sidebar></Sidebar>
    
    <div class="shrink-0 flex flex-col items-center justify-start flex-1 h-full max-h-full p-10 overflow-x-hidden overflow-y-scroll">
        <Router {routes} />
    </div>
</div>

<div class="absolute top-0 right-0 mt-10 mr-10">
    <SvelteToast target="default" options={{
        pausable: true,
        theme: {
            '--toastWidth': '24rem',
            '--toastPadding': '0',
            '--toastMsgPadding': '0',
            '--toastBackground': 'transparent',
            '--toastBarHeight': '0px',
            '--toastBarWidth': '0px',
            '--toastBorderRadius': '0.75rem',
            '--toastMinHeight': '0px'
        },
        duration: (10 * 1000)
    }}/>
</div>

<style global>
    @tailwind base;
    @tailwind components;
    @tailwind utilities;

    * {
        outline: none;
    }

    /* Scrollbar */
    ::-webkit-scrollbar {
        width: 2px;
    }
    ::-webkit-scrollbar-track {
        background: #f1f1f100;
    }
    ::-webkit-scrollbar-thumb {
        background: #888; 
    }
    ::-webkit-scrollbar-thumb:hover {
        background: #555; 
    }
</style>