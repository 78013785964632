<script>
	import { auth, client } from '../lib/data.js';
    import { navigateTo } from 'svelte-router-spa';
    import { onMount } from 'svelte';

    let products = [];
    onMount(async () => {
        getProducts();
    });

    $client.realtime.subscribe('products', () => getProducts());

    async function getProducts() {
        products = await $client.records.getFullList('products', null, {
            expand: 'tags'
        });
    };

    async function newProduct() {
        let data = {
            'description': '',
            'enabled': false,
            'images': [],
            'dimensions': [],
            'mainimage': 'https://via.placeholder.com/150.png',
            'name': 'Untitled Product',
            'price': 100,
            'related': [],
            'tags': [],
            'variations': [],
            'attributes': [],
            'type': 'simple',
            'stocked': false,
            'allowCustom': false
        };
        let product = await $client.records.create('products', data);
        navigateTo('/product/' + product.id);
    };
</script>

{#if products.length > 0}
<div class="flex flex-col items-center justify-center w-full p-5 bg-white rounded-lg">
    <div class="flex flex-row items-center justify-between w-full mb-3">
        <h1 class="text-2xl font-bold text-left">Products ({products.length})</h1>
        <button class="hover:bg-green-500 flex flex-row items-center justify-start p-3 text-lg text-white transition-colors duration-500 ease-in-out bg-green-600 rounded-lg" on:click={() => newProduct()}>
            <img src="/assets/img/plus.svg" class="w-7 aspect-square invert mr-3" alt="Plus Icon" />
            <h3 class="pr-2">Create Product</h3>
        </button>
    </div>

    {#each products as product}
    <span class="w-full px-1 h-[1px] my-2 bg-gray-300"></span>

    <div class="group hover:bg-gray-200 flex flex-row items-center justify-start w-full h-[7.5rem] p-3 rounded-lg cursor-pointer" on:click={() => navigateTo('/product/' + product.id)}>
        <img src={product.mainimage} class="aspect-square rounded-xl object-cover h-24 mr-5 bg-white" alt={product.name}>
        <div class="flex flex-col items-start justify-center flex-1 h-full">
            <h1 class="w-full text-xl font-bold text-left">{product.name} <span class="ml-3 text-base text-gray-600">{product.id}</span></h1>
            <h3 class="text-lg font-semibold"><span class={product.enabled ? 'text-green-600' : 'text-red-600'}>{product.enabled ? 'Enabled' : 'Disabled'}</span> - <span class={product.stocked ? 'text-green-600' : 'text-red-600'}>{product.stocked ? 'In Stock' : 'Out of Stock'}</span></h3>
        
            <div class="flex flex-row flex-wrap items-center justify-start w-full gap-2 mt-1">
                {#each product['@expand'].tags ?? [] as tag}
                    <div class="shrink-0 flex flex-row items-center justify-start px-2 py-1 border-2 rounded-full" style="border-color: {tag.color};">
                        <span class="p-2 mr-2 rounded-full" style="background-color: {tag.color};"></span>
                        <p class="text-sm">{tag.name}</p>
                    </div>
                {/each}
            </div>
        </div>
        <h1 class="mr-5 text-2xl font-bold text-right">${product.price.toFixed(2)}</h1>
        <img src="/assets/img/arrow.svg" class="aspect-square h-12 rotate-90" alt="Arrow">
    </div>
    {/each}
</div>
{/if}

<style>
    
</style>