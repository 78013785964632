<script>
	import { auth, client, shopurl } from '../lib/data.js';
    import { navigateTo } from 'svelte-router-spa';
    import dayjs from 'dayjs';
    import Select from 'svelte-select';
    import { onMount } from 'svelte';
    import Toast from '../lib/toasts';

    let orders = [], selorder = {}, emailType = '--';
    onMount(async () => {
        getOrders();
    });

    $client.realtime.subscribe('orders', () => getOrders());

    async function getOrders() {
        orders = await $client.records.getFullList('orders', 100, {
            sort: '-created',
            expand: 'customer'
        });
    };

    let paymentOptions = [
        { 'show': 'Awaiting Payment',    'value': 'awaiting',      'verb': 'is'},
        { 'show': 'Paid',                'value': 'paid',          'verb': 'has been'},
        { 'show': 'Refunded',            'value': 'refunded',      'verb': 'has been'},
        { 'show': 'Waived',              'value': 'waived',        'verb': 'has been'},
        { 'show': 'Cancelled',           'value': 'cancelled',     'verb': 'has been'}
    ],
    shippingOptions = [
        { 'show': 'Placed',              'value': 'ordered',       'verb': 'has been'},
        { 'show': 'In Progress',         'value': 'inprogress',    'verb': 'is now'},
        { 'show': 'Shipped',             'value': 'shipped',       'verb': 'has been'},
        { 'show': 'Delivered',           'value': 'delivered',     'verb': 'has been'},
        { 'show': 'Ready for Pickup',    'value': 'pickupReady',   'verb': 'is now'},
        { 'show': 'Cancelled',           'value': 'cancelled',     'verb': 'has been'}
    ];

    function singleLineAddress(address) {
        let str = '';

        str += address.line1 + ', ';
        str += address.line2 + ', ';
        str += address.city + ', ';
        str += address.region + ', ';
        str += address.country + ', ';
        str += address.postcode;

        return str;
    };

    async function sendEmail() {
        let confirmation = confirm('Are you sure you want to send this email?');
        if (confirmation != true) return;

        let content = {
            to: selorder.customerEmail,
            subject: '',
            body: ''
        };
        
        // content.body += '<h4 style="font-size: 1.5rem;">Hello!</h4>';

        switch (emailType) {
            case 'payment':
                content.subject = `Your Order ${selorder.payment.verb} ${selorder.payment.show}. [${selorder.nanoid}]`;
                content.body += `<p style="margin-bottom: 2rem;">Your order [${selorder.nanoid}] ${selorder.payment.verb} <b>${selorder.payment.show}</b>.</p>`;
                break;
            case 'fulfilment':
                content.subject = `Your Order ${selorder.fulfilment.verb} ${selorder.fulfilment.show}. [${selorder.nanoid}]`;
                content.body += `<p style="margin-bottom: 2rem;">Your order [${selorder.nanoid}] ${selorder.fulfilment.verb} <b>${selorder.fulfilment.show}</b>.</p>`;
                break;
            case 'tracking':
                content.subject = `Your Order is now being tracked. [${selorder.nanoid}]`;
                content.body += `<p>Your order [${selorder.nanoid}] has been assigned a tracking number.</p><p style="margin-bottom: 2rem;">The tracking number is ${selorder.tracking.no}, or you can click this link: <a href="${selorder.tracking.url}${selorder.tracking.no}">Tracking Link</a></p>`;
                break;
            default:
                return;
        };

        content.body += '<p>Thanks for shopping with us!</p><p>You can check the status of your orders at any time on our <a href="https://therosyshell.co.nz/account">Orders History Page</a>.</p><p>If you need assistance or have any questions, please email us at <a href="mailto:contact@therosyshell.co.nz">contact@therosyshell.co.nz</a>. We are happy to help!</p><p style="margin:0;padding:0;font-size:1rem;margin-top: 0.5rem;">Sincerely</p><p style="font-size: 1.25rem; margin-top: 0.125rem;">The Rosy Shell</p>';
        
        let email = await fetch('https://server.therosyshell.co.nz/email', {
            'method': 'POST',
            'headers': {
                'Authorization': 'Ymjb58aay9tKUQhoYg43vgpm9iLg8dV7',
                'Content-Type': 'application/json'
            },
            'body': JSON.stringify(content)
        }).then(res => { return res.json(); });

        Toast.success('Sent email!');
    };
</script>

{#if orders.length > 0}
<div class="relative flex flex-row items-center justify-between w-full h-screen overflow-hidden">
    <div class="flex flex-col items-center justify-start flex-1 h-full max-h-full overflow-y-auto">
        <div class="rounded-xl flex flex-row items-center justify-between w-full p-5 bg-white">
            <h1 class="text-2xl font-bold text-left">Orders ({orders.filter(a => (a.payment.value != 'awaiting' && (a.fulfilment.value != 'ordered' && a.fulfilment.value != 'inprogress')) != true).length} / {orders.length})</h1>
        </div>

        {#each orders.sort((a, b) => a.created < b.created ? 1 : -1) as order}
        <div id={order.nanoid} class="rounded-xl flex flex-col items-center justify-center hover:bg-gray-200 w-full p-5 mt-5 {order.payment.value != 'awaiting' && (order.fulfilment.value != 'ordered' && order.fulfilment.value != 'inprogress') ? 'bg-gray-300' : 'bg-white'} cursor-pointer" on:click={() => selorder = order}>
                <div class="flex flex-row items-center justify-center w-full">
                    <h1 class="w-full mb-2 text-2xl text-left">[{order.nanoid}]</h1>
                    <h1 class="w-full mb-2 text-2xl text-right">${order.costs.total.toFixed(2)}</h1>
                </div>
                <p class="w-full text-lg text-left">{dayjs.unix(order.orderedDate).format('dddd, D MMMM YYYY - hh:mm a')}</p>
                <div class="status flex flex-row items-center justify-center w-full">
                    <p class="flex-1 text-lg text-left"><b>Payment:</b> {order.payment.show}</p>
                    <p class="flex-1 text-lg text-right"><b>Shipping:</b> {order.fulfilment.show}</p>
                </div>
                <div class="flex flex-row items-center justify-center w-full">
                    <p class="flex-1 text-lg text-left">{order['@expand'].customer.first} {order['@expand'].customer.last}</p>
                    <a class="hover:underline flex-1 text-lg text-right text-blue-500" href="mailto:{order.customerEmail}">{order.customerEmail}</a>
                </div>
            </div>
        {/each}
    </div>
    <div class="flex flex-col items-center justify-start {selorder?.id ? 'w-[45%] px-5 ml-5' : 'w-0'} transition-all duration-500 ease-in-out h-full py-5 bg-white rounded-lg max-h-full overflow-y-auto">
        {#if selorder?.id}
        <div class="flex flex-row items-center justify-center w-full cursor-pointer" on:click={() => selorder = {}}>
            <h1 class="w-full mb-2 text-2xl font-bold text-left">[{selorder.nanoid}]</h1>
            <h1 class="w-full mb-2 text-2xl font-bold text-right">${selorder.costs.total.toFixed(2)}</h1>
        </div>
        <p class="w-full text-lg text-left">{dayjs.unix(selorder.orderedDate).format('dddd, D MMMM YYYY - hh:mm a')}</p>
        <div class="flex flex-row items-center justify-center w-full">
            <p class="flex-1 text-lg text-left">{selorder['@expand'].customer.first} {selorder['@expand'].customer.last}</p>
            <a class="hover:underline flex-1 text-lg text-right text-blue-500" href="mailto:{selorder.customerEmail}">{selorder.customerEmail}</a>
        </div>

        <div class="flex flex-row items-center justify-center w-full gap-5 mt-3">
            <p class="w-full text-lg font-bold text-left">Payment:</p>
            <p class="w-full text-lg font-bold text-left">Shipping:</p>
        </div>
        <div class="flex flex-row items-center justify-center w-full gap-5 mb-1">
            <p class="flex-1 text-lg text-left">{selorder.billing.option.label}</p>
            <p class="flex-1 text-lg text-left">{selorder.shipping.mode.value == 'deliver' ? selorder.shipping.option.label : selorder.shipping.mode.label}</p>
        </div>
        <div class="flex flex-row items-center justify-center w-full gap-5">
            <Select placeholder="Awaiting Payment" items={paymentOptions}  bind:value={selorder.payment}    on:select={async () => await $client.records.update('orders', selorder.id, selorder)} labelIdentifier="show" optionIdentifier="value" isClearable={false} containerStyles="border-radius: 0.75rem; background-color: rgb(226, 232, 240); border: none; width: 100%; margin-bottom: 0.75rem; cursor: pointer;" inputStyles="border-radius: 0.75rem; width: 100%;" />
            <Select placeholder="Order Placed"     items={shippingOptions} bind:value={selorder.fulfilment} on:select={async () => await $client.records.update('orders', selorder.id, selorder)} labelIdentifier="show" optionIdentifier="value" isClearable={false} containerStyles="border-radius: 0.75rem; background-color: rgb(226, 232, 240); border: none; width: 100%; margin-bottom: 0.75rem; cursor: pointer;" inputStyles="border-radius: 0.75rem; width: 100%;" />
        </div>

        <div class="flex flex-row items-center justify-between w-full mb-3">
            <a class="flex flex-col items-center justify-start flex-1 mr-5" target="_blank" href="https://www.google.com/maps/search/?api=1&query={encodeURI(singleLineAddress(selorder.billing.address))}">
                <p class="w-full text-lg font-bold text-left">Billing:</p>
                <p class="w-full text-lg text-left">{selorder.billing.address.line1}</p>
                <p class="w-full text-lg text-left">{selorder.billing.address.line2}</p>
                <p class="w-full text-lg text-left">{selorder.billing.address.city}</p>
                <p class="w-full text-lg text-left">{selorder.billing.address.region}</p>
                <p class="w-full text-lg text-left">{selorder.billing.address.country}</p>
                <p class="w-full text-lg text-left">{selorder.billing.address.postcode}</p>
            </a>
            <a class="flex flex-col items-center justify-start flex-1" target="_blank" href="https://www.google.com/maps/search/?api=1&query={encodeURI(singleLineAddress(selorder.shipping.address))}">
                <p class="w-full text-lg font-bold text-left">Shipping:</p>
                <p class="w-full text-lg text-left">{selorder.shipping.address.line1}</p>
                <p class="w-full text-lg text-left">{selorder.shipping.address.line2}</p>
                <p class="w-full text-lg text-left">{selorder.shipping.address.city}</p>
                <p class="w-full text-lg text-left">{selorder.shipping.address.region}</p>
                <p class="w-full text-lg text-left">{selorder.shipping.address.country}</p>
                <p class="w-full text-lg text-left">{selorder.shipping.address.postcode}</p>
            </a>
        </div>
        
        <div class="flex flex-row items-center justify-center w-full gap-5 mt-2">
            <p class="w-full text-lg font-bold text-left">Tracking Service:</p>
            <p class="w-full text-lg font-bold text-left">Tracking No:</p>
        </div>
        <div class="flex flex-row items-center justify-center w-full gap-5">
            <select bind:value={selorder.tracking.url} on:change={async () => await $client.records.update('orders', selorder.id, selorder)}>
                <option value="https://www.nzpost.co.nz/tools/tracking?trackid=">NZ Post</option>
                <option value="https://www.passtheparcel.co.nz/Pass/Track?barcode=">Pass the Parcel</option>
            </select>
            <input bind:value={selorder.tracking.no} on:change={async () => await $client.records.update('orders', selorder.id, selorder)}>
        </div>

        <p class="w-full text-lg font-bold text-left">Email Notifications:</p>
        <div class="flex flex-row items-center justify-between w-full">
            <select bind:value={emailType}>
                <option value="--">--</option>
                <option value="payment">Payment Status Update [{selorder.payment.show}]</option>
                <option value="fulfilment">Fulfilment Status Update [{selorder.fulfilment.show}]</option>
                <option value="tracking">Tracking Details Added [{selorder.tracking.no}]</option>
                <!-- <option value="optionsNeeded">Custom Details still Needed</option> -->
            </select>
            <button class="shrink-0 min-w-1/3 hover:bg-blue-400 active:bg-blue-600 px-5 py-2 mb-3 ml-5 font-semibold text-white bg-blue-500 rounded-lg" on:click={() => sendEmail()}>Send Notification</button>
        </div>

        <div class="itemslist flex flex-col items-center justify-start w-full">
            {#each selorder.items as item, i}
                <div class="flex flex-col items-center justify-center w-full py-3 border-t border-gray-300" data-search="{item.name} {item.id} {item.tags.join(' ')}">
                    <div class="flex flex-row items-center justify-center w-full mb-1">
                        <img src={item.mainimage} class="aspect-square object-cover w-16 mr-3" alt={item.name}>
                        <a class="flex flex-col items-start justify-center flex-1" href="{shopurl}/product/{item.id}">
                            <h1 class="text-lg">{item.name}</h1>
                            <h1 class="text-lg"><b>Qty: </b>{item.quantity}</h1>
                        </a>
                        <div class="flex flex-col items-start justify-center w-1/4">
                            <h1 class="text-lg">${item.price.toFixed(2)} ea.</h1>
                            <h1 class="text-lg font-bold">${(item.price * item.quantity).toFixed(2)}</h1>
                        </div>
                    </div>

                    {#if item.variant}
                    <p class="w-full text-lg font-bold text-left">{item.variant.label}</p>
                    <div class="grid w-full grid-cols-3">
                        {#each (item.variant?.details ?? []) as detail}
                        <p class="w-full text-lg text-left">{detail.label}: {detail.value}</p>
                        {/each}
                    </div>
                    {/if}
                </div>
            {/each}
        </div>
        {/if}
    </div>
</div>
{/if}

<style>
    input, textarea, select {
        @apply p-3 rounded-xl overflow-hidden bg-slate-200 transition-shadow duration-200 ease-in-out w-full mb-3 placeholder:text-gray-600;
    }
    input:focus, textarea:focus, select:focus, input:active, textarea:active, select:active {
        @apply ring-2 ring-pink-300;
    }
</style>