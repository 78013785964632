import { toast } from '@zerodevx/svelte-toast';
import Toast from './Toast.svelte';

function sendToast(color, message) {
    toast.push({
        target: 'default',
        component: {
            src: Toast,
            props: { message, color },
            sendIdTo: 'toastId'
        },
    });
};

function sendModal(content, btn1, btn2) {
    toast.push({
        target: 'modals',
        component: {
            src: Modal,
            props: { content, btn1, btn2 },
            sendIdTo: 'toastId'
        },
    });
};

const toasts = {
    success: (message) => sendToast('#39db7f', message),
    warning: (message) => sendToast('#FFC500', message),
    danger: (message) => sendToast('#D81A1A', message),
    info: (message) => sendToast('#3b82f6', message),
    modal: (content, btn1, btn2) => sendModal(content, btn1, btn2),
};
export default toasts;