<script>
    import MediaGallery from './MediaGallery.svelte';
    let selected = {};
</script>

<div class="absolute inset-0 flex flex-row items-center justify-end w-full h-screen p-20 bg-black bg-opacity-50 z-[100]">
    <MediaGallery bind:selected role="picker" on:select on:close />
</div>

<style>
    input, textarea, select {
        @apply p-3 rounded-xl overflow-hidden bg-slate-200 transition-shadow duration-200 ease-in-out w-full mb-3 placeholder:text-gray-600;
    }
    input:focus, textarea:focus, select:focus, input:active, textarea:active, select:active {
        @apply ring-2 ring-pink-300;
    }
</style>