<script>
	import { auth, client } from '../lib/data.js';
    import { navigateTo } from 'svelte-router-spa';
    import dayjs from 'dayjs';
    import { onMount } from 'svelte';
    import Chart from 'chart.js/auto';

    let analytics = {}, openOrders = [], recorders = [], lastSixty = [], lastThirty = [], ordersChange = 0, totalOrders = [], profitChange = 0, emailPlan = {}, remEmailCreds = 0;
    onMount(() => {
        getAnalytics();
        graphOrders();
        recentStats();
        getTotals();
        getEmailStats();
    });

    async function getAnalytics() {
        // Analytics
        let plausible = await fetch('https://analytics.izmichael.com/api/v1/stats/aggregate?site_id=therosyshell.co.nz&period=30d&metrics=pageviews&compare=previous_period', {
            headers: {
                'Authorization': 'Bearer yynPN0ycNHJxFQvREHw2PNpkmkBb30h52bRa76J8-W8lQUU2kA1NgXC2bec8LdqU'
            }
        }).then(res => { return res.json() });
        analytics = plausible.results;
    };

    async function graphOrders() {
        // Order Data
        let orders = await $client.records.getFullList('orders', null, {
            sort: '-created',
            filter: `created >= "${dayjs().subtract(1, 'year').format('YYYY-MM-DD HH:mm:ss')}"`
        });
        orders.map(a => a.createdObj = dayjs(a.created));
        orders.map(a => a.dateCode = a.createdObj.format('YYYY-MM'));
        let chartData = orders.reduce((r, a) => {
            r[a.dateCode] = r[a.dateCode] || [];
            r[a.dateCode].push(a);
            return r;
        }, Object.create(null));
        Object.keys(chartData).forEach(a => {
            chartData[a] = chartData[a].length;
        });

        chartData = Object.keys(chartData).map(a => {
            return { y: chartData[a], x: a};
        }).sort((a, b) => a.x > b.x ? 1 : -1);

        // Graph
        const orderCtx = document.getElementById('orderChart');
        const orderChart = new Chart(orderCtx, {
            type: 'bar',
            data: {
                datasets: [{
                    data: chartData,
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(255, 159, 64, 0.2)'
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)'
                    ],
                    borderWidth: 1
                }]
            },
            options: {
                scales: {
                    y: {
                        beginAtZero: true
                    }
                },
                plugins: {
                    legend: {
                        display: false
                    }
                }
            }
        });
    };

    async function recentStats() {
        // Recent Orders
        let recorderlist = await $client.records.getList('orders', 1, 5, {
            sort: '-created'
        });
        recorders = recorderlist.items;
        
        lastSixty = await $client.records.getFullList('orders', null, {
            sort: '-created',
            filter: `created >= "${dayjs().subtract(60, 'day').format('YYYY-MM-DD HH:mm:ss')}"`
        });
        lastThirty = await $client.records.getFullList('orders', null, {
            sort: '-created',
            filter: `created >= "${dayjs().subtract(30, 'day').format('YYYY-MM-DD HH:mm:ss')}"`
        });

        ordersChange = Math.round(((lastThirty.length - (lastSixty.length - lastThirty.length)) / (lastSixty.length - lastThirty.length)) * 100);
        if (ordersChange == Infinity) ordersChange = 100;

        // Profit
        profitChange = Math.round(((lastThirty.map(a => a.costs.total).reduce((b, c) => b + c, 0) - (lastSixty.map(a => a.costs.total).reduce((b, c) => b + c, 0) - lastThirty.map(a => a.costs.total).reduce((b, c) => b + c, 0))) / (lastSixty.map(a => a.costs.total).reduce((b, c) => b + c, 0) - lastThirty.map(a => a.costs.total).reduce((b, c) => b + c, 0))) * 100);
        if (profitChange == Infinity) profitChange = 100;
    };

    async function getTotals() {
        // Order Totals
        openOrders = await $client.records.getFullList('orders', null, {
            sort: '-created',
            filter: `payment ~ "awaiting" && (fulfilment ~ "ordered" || fulfilment ~ "inprogress")`
        });
        totalOrders = await $client.records.getFullList('orders', null, {
            sort: '-created'
        });
    };

    async function getEmailStats() {
        // Email Credits
        let emailkey = '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';
        emailPlan = await fetch('https://api.sendinblue.com/v3/account', {
            method: 'GET',
            headers: {
                'api-key': emailkey
            }
        }).then(res => { return res.json(); });

        remEmailCreds = emailPlan.plan.find(a => a.type == 'free').credits;
    };
</script>

<div class="flex flex-col items-center justify-center w-full">
    <div class="flex flex-row items-center justify-center w-full gap-10 p-5 bg-white rounded-lg">
        <div class="flex flex-col items-center justify-center flex-1">
            <h2 class="text-gray-600">Net Profit (All Time)</h2>
            <h1 class="text-2xl font-bold">${totalOrders.map(a => a.costs.total).reduce((b, c) => b + c, 0).toFixed(2)}</h1>
        </div>
        
        <div class="flex flex-col items-center justify-center flex-1">
            <h2 class="text-gray-600">Net Profit (30 Days)</h2>
            <h1 class="text-2xl font-bold">${(lastThirty.map(a => a.costs.total).reduce((b, c) => b + c, 0)).toFixed(2)} <span class="text-lg {profitChange > 0 ? 'text-green-500' : 'text-red-600'}">({profitChange}% {profitChange > 0 ? '▲' : '▼'})</span></h1>
        </div>
        
        <div class="flex flex-col items-center justify-center flex-1">
            <h2 class="text-gray-600">Open Orders</h2>
            <h1 class="text-2xl font-bold">{openOrders.length}</h1>
        </div>
        
        <div class="flex flex-col items-center justify-center flex-1">
            <h2 class="text-gray-600">Page Views (30 Days)</h2>
            <h1 class="text-2xl font-bold">{analytics.pageviews?.value ?? 0} <span class="text-lg {analytics.pageviews?.change != 0 ? '' : 'hidden'} {analytics.pageviews?.change > 0 ? 'text-green-600' : 'text-red-600'}">({analytics.pageviews?.change ?? 0}% {analytics.pageviews?.change > 0 ? '▲' : '▼'})</span></h1>
        </div>
    </div>

    <div class="flex flex-row items-center justify-center flex-1 w-full mt-5">
        <div class="flex flex-col items-center justify-start flex-1 h-full gap-5 mr-5">
            <div class="flex flex-col items-center justify-center w-full p-5 bg-white rounded-lg">
                <h2 class="w-full text-left text-gray-600">Orders</h2>
                <h1 class="w-full text-2xl font-bold text-left">{totalOrders.length}</h1>
                <h3 class="w-full mb-3 text-left {ordersChange > 0 ? 'text-green-500' : 'text-red-600'}">{ordersChange}% {ordersChange > 0 ? '▲' : '▼'} in last 30 Days</h3>
                <canvas id="orderChart" class="aspect-video"></canvas>
            </div>

            <div class="flex flex-col items-center justify-center w-full p-5 bg-white rounded-lg">
                <h2 class="w-full text-left text-gray-600">Recent Orders</h2>
                {#each recorders as order}
                <div class="rounded-xl flex flex-col items-center justify-center w-full py-4 bg-white cursor-pointer" on:click={() => navigateTo('/orders#' + order.nanoid)}>
                    <div class="flex flex-row items-center justify-center w-full">
                        <h1 class="flex-1 mb-2 text-xl text-left">[{order.nanoid}]</h1>
                        <h1 class="flex-1 mb-2 text-xl text-right">${order.costs.total.toFixed(2)}</h1>
                    </div>
                    <p class="w-full text-left">{dayjs.unix(order.orderedDate).format('dddd, D MMMM YYYY - hh:mm a')}</p>
                    <div class="status flex flex-row items-center justify-center w-full">
                        <p class="flex-1 text-left"><b>Payment:</b> {order.payment.show}</p>
                        <p class="flex-1 text-right"><b>Shipping:</b> {order.fulfilment.show}</p>
                    </div>
                </div>
                {/each}
            </div>
        </div>

        <div class="flex flex-col items-center justify-start w-1/3 h-full gap-5">
            <div class="flex flex-col items-center justify-center w-full p-5 bg-white rounded-lg">
                <h2 class="w-full text-left text-gray-600">Email Service</h2>
                <div class="flex flex-row items-center justify-between w-full">
                    <h1 class="text-2xl font-bold text-left"><span class={remEmailCreds > 200 ? 'text-green-600' : remEmailCreds > 100 ? 'text-yellow-600' : 'text-red-600'}>{remEmailCreds}</span> / {300}</h1>
                    <button class="hover:text-gray-400 text-right text-gray-600" on:click={() => navigateTo('/emails')}>View Logs</button>
                </div>
                <div class="flex flex-row items-center justify-start w-full mt-1 overflow-hidden rounded-full bg-gray-200">
                    <span class="py-2 {remEmailCreds > 200 ? 'bg-green-600' : remEmailCreds > 100 ? 'bg-yellow-600' : 'bg-red-600'}" style="width: {(remEmailCreds / 300) * 100}%;"></span>
                </div>
            </div>
        </div>
    </div>
</div>

<style>

</style>