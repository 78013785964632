// https://github.com/jorgegorka/svelte-router/blob/master/README.md

import Login from './routes/Login.svelte';
import Logout from './routes/Logout.svelte';
import Inbox from './routes/Inbox.svelte';
import Dashboard from './routes/Dashboard.svelte';
import Orders from './routes/Orders.svelte';
import Media from './routes/Media.svelte';
import Emails from './routes/Emails.svelte';
import Products from './routes/Products.svelte';
import ProductEditor from './routes/ProductEditor.svelte';
import Tags from './routes/Tags.svelte';
import Plausible from './routes/Plausible.svelte';
import NotFound from './routes/NotFound.svelte';

const routes = [
    {
        name: '/login',
        component: Login
    },
    {
        name: '/logout',
        component: Logout,
        onlyIf: { guard: () => { if (window.localStorage.getItem('pocketbase_auth') && JSON.parse(window.localStorage.getItem('pocketbase_auth')) != {}) return true }, redirect: '/login' }
    },
    {
        name: '/inbox',
        component: Inbox,
        onlyIf: { guard: () => { if (window.localStorage.getItem('pocketbase_auth') && JSON.parse(window.localStorage.getItem('pocketbase_auth')) != {}) return true }, redirect: '/login' }
    },
    {
        name: '/',
        component: Dashboard,
        onlyIf: { guard: () => { if (window.localStorage.getItem('pocketbase_auth') && JSON.parse(window.localStorage.getItem('pocketbase_auth')) != {}) return true }, redirect: '/login' }
    },
    {
        name: '/orders/:id',
        component: Orders,
        onlyIf: { guard: () => { if (window.localStorage.getItem('pocketbase_auth') && JSON.parse(window.localStorage.getItem('pocketbase_auth')) != {}) return true }, redirect: '/login' }
    },
    {
        name: '/emails',
        component: Emails,
        onlyIf: { guard: () => { if (window.localStorage.getItem('pocketbase_auth') && JSON.parse(window.localStorage.getItem('pocketbase_auth')) != {}) return true }, redirect: '/login' }
    },
    {
        name: '/media',
        component: Media,
        onlyIf: { guard: () => { if (window.localStorage.getItem('pocketbase_auth') && JSON.parse(window.localStorage.getItem('pocketbase_auth')) != {}) return true }, redirect: '/login' }
    },
    {
        name: '/products',
        component: Products,
        onlyIf: { guard: () => { if (window.localStorage.getItem('pocketbase_auth') && JSON.parse(window.localStorage.getItem('pocketbase_auth')) != {}) return true }, redirect: '/login' }
    },
    {
        name: '/product/:id',
        component: ProductEditor,
        onlyIf: { guard: () => { if (window.localStorage.getItem('pocketbase_auth') && JSON.parse(window.localStorage.getItem('pocketbase_auth')) != {}) return true }, redirect: '/login' }
    },
    {
        name: '/tags',
        component: Tags,
        onlyIf: { guard: () => { if (window.localStorage.getItem('pocketbase_auth') && JSON.parse(window.localStorage.getItem('pocketbase_auth')) != {}) return true }, redirect: '/login' }
    },
    {
        name: '/plausible',
        component: Plausible,
        onlyIf: { guard: () => { if (window.localStorage.getItem('pocketbase_auth') && JSON.parse(window.localStorage.getItem('pocketbase_auth')) != {}) return true }, redirect: '/login' }
    },
    {
        name: '404',
        component: NotFound
    }
]

export { routes }