<script>
    import { navigateTo } from 'svelte-router-spa';
    export let path = '/', label = '', img = '';

    let currPath = '';
    let observer = new MutationObserver(() => {
        currPath = window.location.pathname;
    });
    observer.observe(document.body, {
        childList: true,
        subtree: true
    });
</script>

<button class="w-full rounded-lg flex flex-row justify-start items-center text-lg p-3 transition-colors duration-500 ease-in-out {currPath == path ? 'bg-brand text-white' : 'text-black hover:bg-pink-200'}" on:click={() => navigateTo(path)}>
    <img src={img} class="mr-3 {currPath == path ? 'invert' : ''} w-7 aspect-square transition-all duration-500 ease-in-out" alt="{path} Icon">
    <h3>{label}</h3>
</button>