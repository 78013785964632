<script>
    
</script>

<div class="flex flex-col items-center justify-center p-5 bg-white rounded-xl">
    <h1 class="text-4xl">Uh Oh! This page doesn't exist...</h1>
    <h1 class="text-3xl">Do you want to <a href="/">return home</a>?</h1>
</div>

<style>

</style>