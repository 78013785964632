<script>
	import { auth } from '../lib/data.js';
    import { navigateTo, routeIsActive } from 'svelte-router-spa';

    import PageBtn from '../lib/PageBtn.svelte';
</script>

<div class="shrink-0 z-0 flex flex-col items-center justify-start w-1/5 h-full max-h-full gap-2 p-5 overflow-x-hidden overflow-y-scroll bg-white">
    <div class="flex flex-row items-center justify-start w-full mb-5">
        <img src="/favicon.png" class="aspect-square w-12 mr-5 rounded-full" alt="The Rosy Shell Logo">
        <div class="flex flex-col items-center justify-center w-full">
            <h1 class="w-full text-xl">The Rosy Shell</h1>
            <h2 class="w-full text-gray-600">Admin Panel</h2>
        </div>
    </div>

    <!-- <PageBtn path="/inbox" label="Inbox" img="/assets/img/inbox.svg"/> -->
    <PageBtn path="/" label="Dashboard" img="/assets/img/grid.svg"/>
    <PageBtn path="/plausible" label="Analytics" img="/assets/img/search.svg"/>

    <p class="w-full mt-5 -mb-1 text-sm text-left text-gray-600">Operations</p>
    <PageBtn path="/orders" label="Orders" img="/assets/img/delivery.svg"/>
    <PageBtn path="/emails" label="Emails" img="/assets/img/letter.svg"/>
    <PageBtn path="/media" label="Media" img="/assets/img/image.svg"/>

    <p class="w-full mt-5 -mb-1 text-sm text-left text-gray-600">Catalog</p>
    <PageBtn path="/products" label="Products" img="/assets/img/cart.svg"/>
    <PageBtn path="/tags" label="Tags" img="/assets/img/tag.svg"/>

    <!-- <p class="w-full mt-5 -mb-1 text-sm text-left text-gray-600">Analytics</p>
    <PageBtn path="/plausible" label="Website" img="/assets/img/search.svg"/> -->
    <!-- <PageBtn path="/reviews" label="Reviews" img="/assets/img/thumb-up.svg"/> -->

    <!-- <div class="flex-1 px-1"></div> -->

    <p class="w-full mt-5 -mb-1 text-sm text-left text-gray-600">Account</p>
    <PageBtn path="/logout" label="Logout" img="/assets/img/lock-closed.svg"/>
</div>

<!-- <style>
    .btn {
        @apply p-3 text-xl text-center w-full border-b border-gray-300;
        transition: 0.35s;
        position: relative;
        overflow: hidden;
    }
    .btn::after {
        @apply bg-brand;
        top: 0;
        bottom: 0;
        left: -100%;
        right: 100%;
        content: "";
        position: absolute;
        z-index: -1;
        padding: 0.85em 0.75em;
        display: block;
        transition: all 0.35s;
    }
    .btn:hover {
        color: white;
    }
    .btn:hover::after {
        left: 0;
        right: 0;
    }
</style> -->