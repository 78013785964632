import { writable, get } from 'svelte/store';
import Toast from './toasts';

export const auth = writable(localStorage.auth ? JSON.parse(localStorage.getItem('trs-admin-auth')) : {});
auth.subscribe(val => localStorage.setItem('trs-admin-auth', JSON.stringify(val)) );

export const client = writable({});

export async function imageUploadOLD(e) {
    Toast.info('Uploading "' + e.target.files[0].name + '"...');
    
    let formdata = new FormData();
    formdata.append('image', e.target.files[0]);
    formdata.append('name', e.target.files[0].name);

    let imgbb = await fetch('https://api.imgbb.com/1/upload?key=3037e3975cc1f977e2587bd829ed3508', {
        method: 'POST',
        headers: {
            'content-type': 'multipart/form-data'
        },
        body: formdata
    }).then(res => {return res.json()});

    Toast.success('Uploaded!');
    return imgbb.data.url;
};

export async function imageUploadPB(e, productid, field) {
    Toast.info('Uploading "' + e.target.files[0].name + '"...');
    
    let formdata = new FormData();
    formdata.append(field, e.target.files[0]);

    let data = await get(client).records.update('products', productid, formdata);

    Toast.success('Uploaded!');
    return data[field];
};

function getBinary(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.addEventListener("load", () => resolve(reader.result));
        reader.addEventListener("error", err => reject(err));

        reader.readAsBinaryString(file);
    });
};

export async function imageUploadZL(e) {
    Toast.info('Uploading "' + [...e.target.files].map(a => a.name).join('", "') + '"...');
    
    let formdata = new FormData();
    for (const file of e.target.files) {
        formdata.append('file', file);
    };

    let data = await fetch('https://cdn.izmichael.com/api/upload', {
        method: 'POST',
        headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': 'RqlE80VPKYbYdUEVMjToT1J4.MTY2OTMyNjU1MTk3Ng'
        },
        body: formdata
    }).then(res => {return res.json()});

    Toast.success('Uploaded!');
    let outputArr = [];
    for (let i = 0; i < data.files.length; i++) {
        outputArr.push({
            'title': e.target.files[i].name,
            'file': data.files[i]
        });
    };
    return outputArr;
};

export async function imageUpload(e) {
    Toast.info('Uploading "' + [...e.target.files].map(a => a.name).join('", "') + '"...');
    let outputArr = [];

    for (const file of e.target.files) {
        let formdata = new FormData();
        formdata.append('file', file);

        let data = await fetch('https://loqui.izmichael.com/api/uploads', {
            method: 'POST',
            body: formdata
        }).then(res => {
            return res.json();
        });
        // console.log(data);
        
        outputArr.push(data.url);
    };

    Toast.success('Uploaded!');
    return outputArr;
};

export let shopurl = 'https://therosyshell.co.nz';
