<script>
    import MediaPicker from '../lib/MediaPicker.svelte';
    let showPicker = false;
    export let fullw = true, preview = false, value = 'https://via.placeholder.com/150.png';
    
    import { createEventDispatcher } from 'svelte';
	const dispatch = createEventDispatcher();
</script>

<div class="rounded-xl bg-slate-200 group flex flex-row items-center justify-start {fullw == true ? 'w-full' : ''} p-3 pb-2 mb-3 overflow-hidden transition-shadow duration-200 ease-in-out cursor-pointer select-none" on:click={() => showPicker = true}>
    <!-- svelte-ignore a11y-img-redundant-alt -->
    <img src={preview == true ? value : '/assets/img/image.svg'} alt="Image Icon" class="aspect-square object-cover h-10 mr-3 -mt-1">
    <p class="group-hover:text-gray-800 mb-1"><b>Select New Image</b><br>From the media library</p>
</div>

{#if showPicker == true}
    <MediaPicker on:select={(e) => {
        dispatch('select', e.detail);
        showPicker = false;
    }} on:close={() => showPicker = false} />
{/if}

<style>
    
</style>