<script>
	import { auth, client, shopurl } from '../lib/data.js';
    import { navigateTo } from 'svelte-router-spa';
    import dayjs from 'dayjs';
    import utc from 'dayjs/plugin/utc';
    dayjs.extend(utc);
    import Select from 'svelte-select';
    import { onMount } from 'svelte';
    import Toast from '../lib/toasts';

    let emails = [], selemail = {};
    onMount(async () => {
        getEmails();
    });

    $client.realtime.subscribe('emails', () => getEmails());

    async function getEmails() {
        emails = await $client.records.getFullList('emails', 100, {
            sort: '-created'
        });
    };
</script>

{#if emails.length > 0}
<div class="relative flex flex-row items-center justify-between w-full h-screen overflow-hidden">
    <div class="flex flex-col items-center justify-start flex-1 h-full max-h-full overflow-y-auto">
        <div class="rounded-xl flex flex-row items-center justify-between w-full p-5 bg-white">
            <h1 class="text-2xl font-bold text-left">Sent Emails</h1>
        </div>

        {#each emails.sort((a, b) => a.created < b.created ? 1 : -1) as email}
        <div id={email.id} class="rounded-xl flex flex-col items-center justify-center hover:bg-gray-200 w-full p-5 mt-5 bg-white cursor-pointer" on:click={() => selemail = email}>
                <div class="flex flex-row items-center justify-center w-full">
                    <h1 class="w-full mb-2 text-2xl text-left">{email.subject}</h1>
                    <a class="w-full mb-2 text-2xl text-right hover:underline text-blue-500" href="mailto:{email.to}">{email.to}</a>
                </div>
                <p class="w-full text-lg text-left">{dayjs.utc(email.created).local().format('dddd, D MMMM YYYY - hh:mm a')}</p>
            </div>
        {/each}
    </div>
    <div class="flex flex-col items-center justify-start {selemail?.id ? 'w-[45%] px-5 ml-5' : 'w-0'} transition-all duration-500 ease-in-out h-full py-5 bg-white rounded-lg max-h-full overflow-y-auto">
        {#if selemail?.id}
        <a class="w-full mb-2 text-xl text-left hover:underline text-blue-500" href="mailto:{selemail.to}">To: {selemail.to}</a>
        <h1 class="w-full mb-2 text-2xl font-bold text-left">{selemail.subject}</h1>
        <p class="w-full text-lg text-left">{dayjs.utc(selemail.created).local().format('dddd, D MMMM YYYY - hh:mm a')}</p>
        <iframe srcdoc={selemail.content}  class="w-full flex-1 overflow-hidden"/>
        {/if}
    </div>
</div>
{/if}

<style>
    input, textarea, select {
        @apply p-3 rounded-xl overflow-hidden bg-slate-200 transition-shadow duration-200 ease-in-out w-full mb-3 placeholder:text-gray-600;
    }
    input:focus, textarea:focus, select:focus, input:active, textarea:active, select:active {
        @apply ring-2 ring-pink-300;
    }
</style>