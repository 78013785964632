<script>
	import { auth, client } from '../lib/data.js';

    let btn, email = '', password = '', err = { show: false, message: '' };
    
    async function logIn() {
        btn.disabled = true;

        if (email.length < 1 || password.length < 1) {
            err = { show: true, message: 'Invalid Email or Password' };
            return btn.disabled = false;
        } else if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email) != true) {
            err = { show: true, message: 'Invalid Email' };
            return btn.disabled = false;
        } else {
            err.show = false;
        };

        try {
            $auth = await $client.admins.authViaEmail(email, password);
        } catch (error) {
            err = { show: true, message: error.message };
            return btn.disabled = false;
        };

        if ($auth.token) {
            $auth = await $client.admins.refresh();
            if ($auth.model) {
                $auth.user = $auth.model;
                delete $auth.model;
            };
        } else {
            err = { show: true, message: 'Invalid Email or Password' };
            return btn.disabled = false;
        };

        email = '', password = '';
        window.location.href = '/';
    };
</script>

<div class="flex flex-col items-center justify-center w-full h-full">
    <div class="h-2/3 flex flex-col items-center justify-center w-1/2 px-10 py-5 bg-white rounded-lg">
        <h1 class="w-full mb-10 text-2xl font-bold text-center">Login to The Rosy Shell</h1>

        <p>Email Address</p>
        <input placeholder="john.doe@email.tld" bind:value={email} name="email" on:keydown={e => { if (e.key == 'Enter') logIn(); }}>
        <p>Password</p>
        <input placeholder="*********" type="password" bind:value={password} name="password" on:keydown={e => { if (e.key == 'Enter') logIn(); }}>
        <button class="rounded-xl bg-brand w-full px-5 py-2 text-white" on:click={() => logIn()} bind:this={btn}>Log In</button>
        {#if err.show == true}
        <p class="text-brand pt-1 font-bold">{err.message}</p>
        {/if}
    </div>
</div>

<style>
    p {
        @apply w-full text-left mb-1;
    }
    a {
        @apply w-full mb-1 text-brand underline;
    }
    input {
        @apply p-3 rounded-xl overflow-hidden bg-slate-200 transition-shadow duration-200 ease-in-out w-full mb-3 placeholder:text-gray-600;
    }
    input:focus, input:active {
        @apply ring-2 ring-pink-300;
    }
</style>